import React from 'react';
import FAQ from "../../../components/FAQ";
import enJson from '../../translations/en.json';
import ItJson from "../../translations/it.json";

function Faqs() {
    const hideComponent = true;

    return (
        <FAQ
            faqTitleSection={enJson.faqTitleSection}
            faqList={enJson.faq}
            otherQuestions={enJson.otherQuestions}
            notes={enJson.notes}
            hideComponent={hideComponent}
        />
    )
}

export default Faqs;